import React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { AppProvider, Card, ThemeProvider } from "@shopify/polaris"
import en from '@shopify/polaris/locales/en.json';
import GraphqlProvider from "../providers/graphql"
import { getFirebase } from "../helpers/firebase"
import FirebaseContext from "../providers/firebase"
import { TourProvider } from '@reactour/tour'
import { refreshAuth } from "../helpers/auth"
import { Crisp } from "crisp-sdk-web";
import { v4 as uuidv4 } from 'uuid'; 
import '@shopify/polaris/build/esm/styles.css';

import { 
    getShopDomain,
    signin,
} from "../helpers/auth"

const CustomLinkComponent = ({ children, url, external, ...rest }) => {
    if (external) {
        return (
            <a
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                {...rest}
            >
                {children}
            </a>
        )
    }

    return (
        <Link
            to={url}
            {...rest}
        >
            {children}
        </Link>
    )
}
class AppLayout extends React.Component {
    constructor(props) {
        super(props)

        let shop = null

        if (typeof window !== 'undefined') {
            shop = getShopDomain()

            if (!shop || shop === null || typeof shop == "undefined") {
                navigate(
                    `/install/`,
                    {
                        replace: true,
                    }
                )
            }
        }

        this.state = {
            shop,
            token: null,
            isLoading: true,
        }
    }

    componentDidMount = async () => {
        const queryParams = window.location.search
        const urlParams = new URLSearchParams(queryParams)
        const id_token = urlParams.get("id_token")
        const shop = urlParams.get("shop")
        let token = urlParams.get("token")
        let uid = urlParams.get("uid")
        const host = urlParams.get("host")
        let user_id = urlParams.get("user_id")
        if (!id_token && !uid) {
            await refreshAuth(shop, host)
        }else{
            if(id_token && !uid){
              const customToken = await signin(shop, host, id_token)
              uid = customToken?.uid
              token = customToken?.token
              user_id = customToken?.user_id
            }
        } 
        if (uid) {
            
                const firebaseInstance = getFirebase(uid)
        
        
                this.setState({
                    firebase: firebaseInstance,
                    token,
                    host,
                    user_id,
                    isLoading: false,
                })
            Crisp.configure("d706d890-199d-4990-b295-9d23ebbaf88a", {
                autoload: false
              });
            Crisp.setTokenId(user_id || uuidv4());
            Crisp.session.setData({
                company : shop
            });
            Crisp.load();
        } else {
            // show login button/screen -- redirect to reauth
            console.warn('no user token found')
        }
        
    }

    render() {
        const { shop, token, host, user_id, isLoading, firebase } = this.state
        //let appTitle = '' // convert to new Gatsy useStaticQuery hook
        let content = (
            <Card>
                <Card.Section>
                    <p>Initializing app...</p>
                </Card.Section>
            </Card>
        )
        if (!shop || shop === null || typeof shop == "undefined") {
            return <p>Redirecting...</p>
        }

        console.log('shop in app render: ', shop, user_id)

        if (isLoading) {
            content = (
                <Card>
                    <Card.Section>
                        <p>Initializing app...</p>
                    </Card.Section>
                </Card>
            )
        } else {
            content = (
                <FirebaseContext.Provider value={{firebase, shop, token, host, user_id}}>
                    <GraphqlProvider
                        shop={shop}
                        token={token}
                    >
                        <TourProvider steps={[]}
                        styles={{
                            popover: (base) => ({
                              ...base,
                              '--reactour-accent': '#038060',
                              borderRadius: '0.8rem',
                            }),
                            maskArea: (base) => ({ ...base, rx: '0.8rem' }),
                            maskWrapper: (base) => ({ ...base }),
                            badge: (base) => ({ ...base }),
                            controls: (base) => ({ ...base }),
                            close: (base) => ({ ...base }),
                          }}
                        scrollSmooth>
                        {this.props.children}
                        </TourProvider>
                    </GraphqlProvider>
                </FirebaseContext.Provider>
            )
        }
        return (
            <AppProvider
                i18n={en}
                apiKey={process.env.GATSBY_SHOPIFY_APP_API_KEY}
                linkComponent={CustomLinkComponent}
                forceRedirect={(process.env.NODE_ENV === 'development') ? false : true}
            >
                <ThemeProvider alwaysRenderCustomProperties>
                    {content}
                </ThemeProvider>
            </AppProvider> 
        )
    }
}

AppLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default AppLayout
